import React from "react";
import { graphql } from "gatsby";

import REGION from "../../enums/region.json";

import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";
import StatisticsTable from "../components/statistics-table";

export const query = graphql`
  query StatisticsQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        statistics
      }
      enums {
        region
      }
      statisticsTable
      ...LayoutTranslations
    }
  }
`;

const REGION_KEYS = Object.keys(REGION);

const Statistics = () => {
  const t = useTranslations("pages.statistics");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section>
        <StatisticsTable displayedRegions={REGION_KEYS} />
      </section>
    </>
  );
};

export default Statistics;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import REGION from "../../enums/region.json";
import REPORT_STATUS from "../../enums/report-status.json";

import IconArrow from "../assets/icons/arrow.svg";

import { reportError } from "../utils/error-handler";
import { getReportStatistics } from "../api/violation-reports";
import { useTranslations } from "../hooks/translations";

import styles from "./statistics-table.module.scss";

const ExpandableSection = ({ label, children, onClick, isActive }) => (
  <>
    <button
      type="button"
      className={`bp-between ${styles.expandableSectionButton}`}
      onClick={onClick}
    >
      <span className={styles.expandableSectionLabel}>{label}</span>
      <IconArrow className={`bp-stroke ${isActive ? styles.active : null}`} />
    </button>
    <div className={isActive ? null : "bp-hidden"}>{children}</div>
  </>
);

ExpandableSection.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

ExpandableSection.defaultProps = {
  isActive: false
};

const StatisticsTable = ({ displayedRegions }) => {
  const { reportStatistics } = useStaticQuery(
    graphql`
      query {
        reportStatistics
      }
    `
  );

  const t = useTranslations("statisticsTable");

  const enums = useTranslations("enums");

  const [expandedRegions, setExpandedRegions] = useState({});

  const [rawStatistics, setRawStatistics] = useState(reportStatistics);

  const stats = useMemo(() => {
    if (!rawStatistics) {
      return null;
    }

    const processed = {};

    Object.keys(REGION).forEach(regionKey => {
      processed[regionKey] = {};

      Object.keys(REPORT_STATUS).forEach(statusKey => {
        processed[regionKey][statusKey] = Object.values(rawStatistics[regionKey][statusKey]).reduce(
          (total, count) => total + count,
          0
        );
      });
    });

    return processed;
  }, [rawStatistics]);

  const toggleExpandableSection = useCallback(
    regionKey => {
      setExpandedRegions({ ...expandedRegions, [regionKey]: !expandedRegions[regionKey] });
    },
    [expandedRegions, setExpandedRegions]
  );

  useEffect(() => {
    getReportStatistics({
      next: doc => setRawStatistics(doc.data()),
      error: reportError
    });
  }, []);

  const totals = useMemo(() => {
    const totalByRegion = {};
    displayedRegions.forEach(regionKey => {
      totalByRegion[regionKey] = Object.keys(REPORT_STATUS).reduce(
        (total, statusKey) => total + stats[regionKey][statusKey],
        0
      );
    });
    return totalByRegion;
  }, [displayedRegions, stats]);

  return (
    <div className="bp-container">
      <h1>{t.heading}</h1>
      <p className="bp-subheading">{t.description}</p>

      {stats ? (
        <>
          <table className={styles.desktopTable}>
            <thead>
              <tr>
                <th>
                  <span className="bp-visually-hidden">{t.region}</span>
                </th>
                {Object.keys(REPORT_STATUS).map(statusKey => (
                  <th key={statusKey}>{t.status[statusKey]}</th>
                ))}
                <th className={styles.total}>{t.total}</th>
              </tr>
            </thead>
            <tbody>
              {displayedRegions.map(regionKey => {
                const statistics = stats[regionKey] || {};

                return (
                  <tr key={regionKey}>
                    <td>
                      {enums.region[regionKey]}{" "}
                      <span className={styles.regionLabel}>{t.region}</span>
                    </td>
                    {Object.keys(REPORT_STATUS).map(statusKey => (
                      <td key={statusKey}>{statistics[statusKey]}</td>
                    ))}
                    <td className={styles.total}>{totals[regionKey]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={styles.tabletAndMobileSection}>
            {displayedRegions.map(regionKey => {
              const statistics = stats[regionKey] || {};

              return (
                <ExpandableSection
                  key={regionKey}
                  label={
                    <>
                      {enums.region[regionKey]}{" "}
                      <span className={styles.regionLabel}>{t.region}</span>
                    </>
                  }
                  onClick={() => toggleExpandableSection(regionKey)}
                  isActive={expandedRegions[regionKey]}
                >
                  <table className={styles.tabletTable}>
                    <thead>
                      <tr>
                        {Object.keys(REPORT_STATUS).map(statusKey => (
                          <th key={statusKey}>{t.status[statusKey]}</th>
                        ))}
                        <th className={styles.total}>{t.total}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Object.keys(REPORT_STATUS).map(statusKey => (
                          <td key={statusKey}>{statistics[statusKey]}</td>
                        ))}
                        <td className={styles.total}>{totals[regionKey]}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className={styles.mobileTable}>
                    <tbody>
                      {Object.keys(REPORT_STATUS).map(statusKey => (
                        <tr key={statusKey}>
                          <th>{t.status[statusKey]}</th>
                          <td>{statistics[statusKey]}</td>
                        </tr>
                      ))}
                      <tr>
                        <th className={styles.total}>{t.total}</th>
                        <td className={styles.total}>{totals[regionKey]}</td>
                      </tr>
                    </tbody>
                  </table>
                </ExpandableSection>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

StatisticsTable.propTypes = {
  displayedRegions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default StatisticsTable;
